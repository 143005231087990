import axios from 'axios';

let prefix = "/voucher"

const VoucherApi = {
	
	getVoucherInfo(id, payload){
		return axios.get( prefix + "/get/"+id, {params: payload})
	},
	getVoucherSortList(){
		return axios.get( prefix + "/sort-list")
	},
	getVoucherCodeList(payload){
		return axios.get( prefix + "/list-voucher-code", {params: payload})
	},
	getVoucherList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	exportVoucherCode(payload){
		return axios.get( prefix + "/export", {params: payload})
	},
	createVoucher(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateVoucher(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveVoucher(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
	setVoucherImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/set-image", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearVoucherImage(payload){
		return axios.delete(prefix + "/clear-image", {params: payload})
	},
	bulkUploadVoucher(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload-voucher-code", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
}

export default VoucherApi;