import axios from 'axios';

let prefix = "/center-location"

const CenterLocationApi = {
	
	getCenterLocationList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getCenterLocationInfo(id, payload){
		return axios.get( prefix + "/get/"+id, {params: payload})
	},
	createCenterLocation(payload){
		return axios.post( prefix + "/create", payload)
	},
	deleteCenterLocation(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	updateCenterLocation(payload){
		return axios.post( prefix + "/update", payload)
	},

}

export default CenterLocationApi;