import axios from 'axios';

let prefix = "/user-voucher"

const UserVoucherApi = {
	
	getUserVoucherList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createUserVoucher(payload){
		return axios.post( prefix + "/create", payload)
	},
	archiveUserVoucher(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
}

export default UserVoucherApi;