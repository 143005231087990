import axios from 'axios';

let prefix = "/pick-up-location"

const PickupLocationApi = {

	getPickupLocationList(payload) {
		return axios.get(prefix + "/list", { params: payload })
	},
	createPickupLocation(payload) {
		return axios.post(prefix + "/create", payload)
	},
	deletePickupLocation(payload) {
		return axios.delete(prefix + "/delete", { params: payload })
	},
	updatePickupLocation(payload) {
		return axios.post(prefix + "/update", payload)
	},

}

export default PickupLocationApi;