import axios from 'axios';

let prefix = "/user"
let prefix_auth = "/auth"

const UserApi = {

	getUserList(payload) {
		return axios.get(prefix + "/list", { params: payload })
	},
	getLockUserList(payload) {
		return axios.get(prefix + "/lock-list", { params: payload })
	},
	getUserCountryList(payload) {
		return axios.get(prefix + "/list-country", { params: payload })
	},
	exportUserCountryList(payload) {
		return axios.get(prefix + "/export-list-country", { params: payload })
	},
	getUserCityList(payload) {
		return axios.get(prefix + "/list-city", { params: payload })
	},
	getAvailableUplineReferralCode(payload) {
		return axios.get(prefix + "/get-available-upline-referral-code", { params: payload })
	},
	exportUserCityList(payload) {
		return axios.get(prefix + "/export-list-city", { params: payload })
	},
	exportUserList(payload) {
		return axios.get(prefix + "/export-user-list", { params: payload })
	},
	changePassword(payload) {
		return axios.post(prefix + "/profile-password/update", payload)
	},
	updateUserProfile(payload) {
		return axios.post(prefix_auth + "/update-profile", payload)
	},
	forgotPassword(payload) {
		return axios.post(prefix_auth + "/reset-password", payload)
	},
	verifyEmail(payload) {
		return axios.post(prefix + "/verifyEmailToken", payload)
	},
	getUserInfo(id, payload) {
		return axios.get(prefix + "/get/" + id, { params: payload })
	},
	createUser(payload) {
		return axios.post(prefix + "/create", payload)
	},
	updateUser(payload) {
		return axios.post(prefix + "/update", payload)
	},
	archiveUser(payload) {
		return axios.delete(prefix + "/delete", { params: payload })
	},
	updateUserPassword(payload) {
		return axios.post(prefix + "/password/update", payload)
	},
	sendAccountActivation(payload) {
		return axios.post(prefix + "/activation-email/send", payload)
	},
	updateRole(payload) {
		return axios.post(prefix + "/update-role", payload)
	},
	createStaff(payload) {
		return axios.post(prefix + "/create-staff", payload)
	},
	updateStaff(payload) {
		return axios.post(prefix + "/update-staff", payload)
	},
	getUserByQrCode(id, payload) {
		return axios.get(prefix + "/get-by-qrcode/" + id, { params: payload })
	},
	unlockAccount(payload) {
		return axios.post(prefix + "/unlock-account", payload)
	},
}

export default UserApi;