import axios from 'axios';

let prefix = "/system-variable"
let app_prefix = "/app-info"

const SystemVariableApi = {
	
	listSystemVariable(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	listRpcUrl(payload){
		return axios.get( prefix + "/rpc-url-list", {params: payload})
	},

	updateRpcUrl(payload){
		return axios.post( prefix + "/update-rpc-url", payload)
	},

	getDownloadUrl(payload){
		return axios.get( prefix + "/download-link", payload)
	},

	getSecuritySetting(payload){
		return axios.get( prefix + "/security-setting", {params: payload})
	},

	updateSecuritySetting(payload){
		return axios.post( prefix + "/update-security-setting", payload)
	},

	getPasswordSetting(payload){
		return axios.get( prefix + "/password-setting", {params: payload})
	},
	
	updatePasswordSetting(payload){
		return axios.post( prefix + "/update-password-setting", payload)
	},

	startupInfo(payload){
		return axios.get( app_prefix + "/startup-info", {params: payload})
	},

}

export default SystemVariableApi;