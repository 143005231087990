import axios from 'axios';

let prefix = "/pick-up"

const PickupApi = {

	getPickupStatusList(payload) {
		return axios.get(prefix + "/status-list", { params: payload })
	},
	updatePickupStatus(payload) {
		return axios.post(prefix + "/update-status", payload)
	},
}

export default PickupApi;