import axios from 'axios';

let prefix = "/location-price"

const LocationPriceApi = {
	getLocationPriceList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createLocationPrice(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateLocationPrice(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveLocationPrice(payload){
		return axios.delete( prefix + "/archive", { params: payload })
	},
}

export default LocationPriceApi;